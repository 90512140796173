import React from "react"

import SEO from "../components/atoms/seo"
import ParticleRing from "../components/atoms/particle-ring"
import VerticalLayout from "../layouts/vertical-layout"

const NotFoundPage = () => (
  <VerticalLayout className='not-found' scene={() => <ParticleRing />}>
    <SEO
      title='404'
      description="You just hit a route that doesn't exist. Sadness."
      image='willow.webp'
    />
    <h1>404: The Void</h1>
    <hr />
    <h2>You just hit a route that doesn&#39;t exist.</h2>
    <hr />
    <p>Sadness.</p>
  </VerticalLayout>
)

export default NotFoundPage
