import React from "react"

import * as THREE from "three"
import { EffectComposer } from "three/examples/jsm/postprocessing/EffectComposer"
import { RenderPass } from "three/examples/jsm/postprocessing/RenderPass"
import { UnrealBloomPass } from "three/examples/jsm/postprocessing/UnrealBloomPass"
import { AfterimagePass } from "three/examples/jsm/postprocessing/AfterimagePass"

import { remap } from "../../utils/math"
import { MobileTestContext } from "../../utils/support"

export default class ParticleRing extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", this.resize)
    window.addEventListener("mousemove", this.onMouseMove)

    this.mount.addEventListener("mouseleave", this.onMouseLeave)

    this.NUM_PARTICLES = 1000
    this.bloom = {
      strength: this.context.isMobile ? 3 : 1.5,
      threshold: 0,
      radius: 0
    }

    this.start()
    this.animate()
  }

  componentWillUnmount() {
    this.mount.removeEventListener("mouseleave", this.onMouseLeave)

    window.removeEventListener("resize", this.resize)
    window.removeEventListener("mousemove", this.onMouseMove)
    window.cancelAnimationFrame(this.requestID)
  }

  start = () => {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight

    this.scene = new THREE.Scene()

    this.clock = new THREE.Clock()

    this.camera = new THREE.PerspectiveCamera(75, width / height, 0.1, 1000)
    this.camera.position.z = 25

    this.renderer = new THREE.WebGLRenderer({
      antialias: !this.context.isMobile,
      precision: this.context.isMobile ? "lowp" : "mediump",
    })
    this.renderer.setPixelRatio(window.devicePixelRatio)
    this.renderer.setSize(width, height)
    this.renderer.toneMapping = THREE.ACESFilmicToneMapping
    this.renderer.toneMappingExposure = this.context.isMobile ? 2 : 1
    this.renderer.outputEncoding = THREE.sRGBEncoding

    const renderPass = new RenderPass(this.scene, this.camera)

    const bloomPass = new UnrealBloomPass(
      new THREE.Vector2(width, height),
      this.bloom.strength,
      this.bloom.radius,
      this.bloom.threshold,
    )

    const afterImagePass = new AfterimagePass(0.98)

    this.composer = new EffectComposer(this.renderer)
    this.composer.addPass(renderPass)
    this.composer.addPass(afterImagePass)
    this.composer.addPass(bloomPass)

    this.mount.appendChild(this.renderer.domElement)

    // Particles
    const particles = new Float32Array(this.NUM_PARTICLES * 3)
    const vertex = new THREE.Vector3();
    for (let i = 0; i < this.NUM_PARTICLES; i += 3) {
      vertex.x = (Math.random() - 0.5) * 50
      vertex.y = (Math.random() - 0.5) * 50
      vertex.z = 0
      vertex.toArray(particles, i * 3)
    }
    const particleGeometry = new THREE.BufferGeometry()
    particleGeometry.setAttribute("position", new THREE.BufferAttribute(particles, 3))

    const pointsMat = new THREE.PointsMaterial({ size: 0.01 })
    this.particles = new THREE.Points(particleGeometry, pointsMat)
    this.particleGroup = new THREE.Group();
    this.particleGroup.add(this.particles)
    this.scene.add(this.particleGroup)
  }

  onMouseMove = event => {
    this.mousePos = new THREE.Vector2(event.x, window.innerHeight - event.y)
  }

  onMouseLeave = () => {
    this.mousePos = false
  }

  resize = () => {
    const width = this.mount.clientWidth
    const height = this.mount.clientHeight

    this.camera.aspect = width / height
    this.camera.updateProjectionMatrix()

    this.renderer.setSize(width, height)
    this.composer.setSize(width, height)
  }

  animate = () => {
    if (this.model) this.model.rotation.y += 0.0005

    const particles = this.particles.geometry.attributes.position.array
    const time = this.clock.getElapsedTime()
    const height = this.mount.clientHeight
    
    const wander = 0.2
    const freq = 100

    let mouseY = this.mousePos ? this.mousePos.y : 0
    mouseY = remap(mouseY, 0, height, -14, 4)

    let radius = Math.sin(time) * 5 + 12

    for (let i = 0; i < this.NUM_PARTICLES * 3; i += 3) {
      if (this.mousePos === false || this.mousePos === undefined) {
        particles[i] += (Math.random() - 0.5) * wander
        particles[i + 1] += (Math.random() - 0.5) * wander
        particles[i + 2] = Math.sin(time + i * 100) * radius
      } else {
        particles[i] = Math.cos(time + i) * radius
        particles[i + 1] = mouseY
        particles[i + 1] += Math.sin(time + i * freq) * 2
        particles[i + 2] = Math.sin(time + i) * radius
      }
    }

    this.particleGroup.rotateX(Math.sin(time) * Math.PI / 180);
    this.particleGroup.rotateY(Math.cos(time) * Math.PI / 180);
    this.particleGroup.rotateZ(Math.cos(time) * Math.PI / 180);
    
    this.particles.geometry.attributes.position.needsUpdate = true

    this.composer.render()
    this.requestID = window.requestAnimationFrame(this.animate)
  }

  render() {
    return <div style={{ width: "100vw", height: "100vh" }} ref={ref => (this.mount = ref)} />
  }
}

ParticleRing.contextType = MobileTestContext
